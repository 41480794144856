/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-02-08",
    versionChannel: "nightly",
    buildDate: "2023-02-08T00:19:50.635Z",
    commitHash: "83f88a6f3e6757993d09b6bc97303739f86d4c30",
};
