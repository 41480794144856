import "./load-options";
export const DEFAULT_CONFIG = {
    allowScriptAccess: false,
    parameters: {},
    autoplay: "auto" /* AutoPlay.Auto */,
    backgroundColor: null,
    letterbox: "fullscreen" /* Letterbox.Fullscreen */,
    unmuteOverlay: "visible" /* UnmuteOverlay.Visible */,
    upgradeToHttps: true,
    warnOnUnsupportedContent: true,
    logLevel: "error" /* LogLevel.Error */,
    showSwfDownload: false,
    contextMenu: true,
    preloader: true,
    maxExecutionDuration: { secs: 15, nanos: 0 },
    base: null,
    menu: true,
    salign: "",
    quality: "high",
    scale: "showAll",
    wmode: "opaque" /* WindowMode.Opaque */,
    publicPath: null,
    polyfills: true,
    playerVersion: null,
};
